import { generateOfferFeatures } from '~/scripts/helpers'

export const state = () => ({
	offers: null,
	airportAndCitiesNames: null,
})

export const actions = {
	async getAviaOffers({commit, rootGetters}, query) {
		commit('clearOffers')
		try {
			let offers = (await this.$api.get('/avia/offers?' + query)).data.data
			const now = new Date(Math.max(Date.now(), rootGetters.serverDate))
			const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000)
			let directionsLength = 0

			offers.forEach(offer => {
				if (offer.directions.length > directionsLength) {
					directionsLength = offer.directions.length
				}

				offer.isOpen = false
				offer.features = generateOfferFeatures(offer)
				offer.directions.forEach(direction => {
					direction.segments.forEach(segment => {
						segment.arrival.date_time = segment.arrival.date_time.replace(/-/g, '/')
						segment.departure.date_time = segment.departure.date_time.replace(/-/g, '/')

						if (offer.type === 'TI' && segment.airline.code === 'FZ') { // Fix for TI provider
							segment.airline.name = 'Fly Dubai'
						}
					})
					direction.arrival.date_time = direction.arrival.date_time.replace(/-/g, '/')
					direction.departure.date_time = direction.departure.date_time.replace(/-/g, '/')
					direction.airlineLogos = generateAirlinesLogos(direction)

					if (offer.type === 'TI') { // Fix for TI provider
						direction.airlines.forEach(airline => {
							if (airline.code === 'FZ') {
								airline.name = 'Fly Dubai'
							}
						})
					}
				})
				offer.ticketing_time_limit = offer.ticketing_time_limit ? offer.ticketing_time_limit.replace(/-/g, '/') : null
				const minutesToPay = offer.ticketing_time_limit ? Math.floor((new Date(offer.ticketing_time_limit) - utc) / 60000) : null
				offer.minutesToPay = minutesToPay > 10 ? minutesToPay : null
			})

			offers = offers.filter(offer => offer.directions.length === directionsLength) // Временно скрываем офферы с не правильным количеством направлений (провайдер DA - Немо)
			const filteredOffers = offers.filter(offer => Math.max(...offer.directions.map(direction => direction.segments.length)) <= 3) //offers with transfers count no more than 3
			commit('setOffers', filteredOffers.length ? filteredOffers : offers)
			return filteredOffers.length ? filteredOffers : offers
		} catch (e) {
			if (e.response?.status === 404) {
				commit('setOffers', [])
				return []
			}
			commit('clearOffers')
			commit('setError', e, {root: true})
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async getAviaOffer({commit, rootGetters}, {id, marketingChannel, marketingMarket}) {
		try {
			let query = id

			if (marketingChannel) {
				query += '?marketing_channel=' + marketingChannel

				if (marketingMarket) {
					query += '&marketing_market=' + marketingMarket
				}
			}

			const response = (await this.$api.get('/avia/offers/' + query)).data
			const createdAt = response.created_at.replace(/-/g, '/')
			const offer = response.data
			const now = new Date(Math.max(Date.now(), rootGetters.serverDate))
			const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000)

			offer.directions.forEach(direction => {
				direction.segments.forEach(segment => {
					segment.arrival.date_time = segment.arrival.date_time.replace(/-/g, '/')
					segment.departure.date_time = segment.departure.date_time.replace(/-/g, '/')
				})
				direction.arrival.date_time = direction.arrival.date_time.replace(/-/g, '/')
				direction.departure.date_time = direction.departure.date_time.replace(/-/g, '/')
				direction.airlineLogos = generateAirlinesLogos(direction)
			})

			offer.ticketing_time_limit = offer.ticketing_time_limit ? offer.ticketing_time_limit.replace(/-/g, '/') : null
			offer.isOpen = false
			offer.features = generateOfferFeatures(offer)
			offer.hoursToPay = offer.ticketing_time_limit ? Math.floor((new Date(offer.ticketing_time_limit) - utc) / 3600000) : null
			offer.minutesToPay = offer.ticketing_time_limit ? Math.floor((new Date(offer.ticketing_time_limit) - utc) / 60000) : null
			offer.deadline = offer.ticketing_time_limit ? Date.now() + (new Date(offer.ticketing_time_limit) - utc) : null
			offer.createdAt = Date.now() + (new Date(createdAt) - utc)
			offer.passengers.sort((a, b) => {
				const left = a.age === 'chd' ? 2 : a.age === 'inf' ? 1 : 0
				const right = b.age === 'chd' ? 2 : b.age === 'inf' ? 1 : 0
				return left - right
			})
			return offer
		} catch (e) {
			if (e.response?.status !== 422) {
				commit('setError', e, {root: true})
			}
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async createAviaBooking({commit}, form) {
		try {
			const response = await this.$api.post('/avia/booking', {
				...form,
				payment_locale: this.$i18n.locale,
			})
			return response.data.data
		} catch (e) {
			if (![5231, 5232].includes(e.response?.data?.error_code)) {
				commit('setError', e, {root: true})
			}

			this.$sentry?.captureException(e)
			throw e
		}
	},
	async getAviaOfferRules({commit}, id) {
		try {
			const response = await this.$api.get(`/avia/offers/${id}/rules`)
			return response.data.data
		} catch (e) {
			if (e.response?.status === 404) {
				return []
			}
			commit('setError', e, {root: true})
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async getFareFamily({commit, rootGetters}, {id, marketingChannel}) {
		try {
			const query = `/avia/offers/${id}/fare-family${marketingChannel ? ('?marketing_channel=' + marketingChannel) : ''}`
			const offers = (await this.$api.get(query)).data.data
			const now = new Date(Math.max(Date.now(), rootGetters.serverDate))
			const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000)
			offers.forEach(offer => {
				offer.isOpen = false
				offer.features = generateOfferFeatures(offer)
				offer.directions.forEach(direction => {
					direction.airlineLogos = generateAirlinesLogos(direction)
				})
				offer.minutesToPay = offer.ticketing_time_limit ? Math.floor((new Date(offer.ticketing_time_limit) - utc) / 60000) : null
			})
			return offers
		} catch (e) {
			if (e.response?.status === 404) {
				return []
			}

			if (e.response?.status !== 422) {
				this.$sentry?.captureException(e)
			}

			throw e
		}
	},
	async getAirportHints({commit}, {phrase, limit}) {
		try {
			const response = await this.$api.get(`/avia/airport-hints?phrase=${phrase}&limit=${limit}`)
			const hints = response.data.data
			const astana = Array.isArray(hints.airports) && hints.airports.find(airport => airport.code === 'NQZ')

			if (astana) {
				const { name, city } = astana
				astana.name = city
				astana.city = name
			}

			return hints
		} catch (e) {
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async getAirportByCode({commit}, codes) {
		try {
			const response = await this.$api.get(`/avia/airport-by-code?iata[]=${codes.join('&iata[]=')}`)
			return response.data.data
		} catch (e) {
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async getAirportAndCitiesNames({commit}) {
		try {
			/*if (process.client && localStorage) {
				const names = JSON.parse(localStorage.getItem('airportAndCities') || null)
				if (typeof names === 'object' && !Array.isArray(names) && names !== null && Object.keys(names).length) {
					commit('setAirportAndCitiesNames', names)
					return names
				}
			}*/

			const names = (await this.$api.get('/avia/airports-and-cities')).data.data
			commit('setAirportAndCitiesNames', names)

			if (process.client && localStorage) {
				localStorage.setItem('airportAndCities', JSON.stringify(names));
			}

			return names
		} catch (e) {
			//commit('setError', e, {root: true})
			this.$sentry?.captureException(e)
			throw e
		}
	},
	async getAdditionalServices({commit}, payload) {
		try {
			const response = await this.$api.get('/additional-services', { params: payload })
			return response.data.data
		} catch (e) {
			this.$sentry?.captureException(e)
			throw e
		}
	},
	clearOffers({commit}) {
		commit('clearOffers')
	}
}

export const mutations = {
	setOffers(state, payload) {
		state.offers = payload
	},
	clearOffers(state) {
		state.offers = null
	},
	setAirportAndCitiesNames(state, payload) {
		state.airportAndCitiesNames = payload
	}
}

export const getters = {
	offers(state) {
		return state.offers
	},
	airportAndCitiesNames(state) {
		return state.airportAndCitiesNames
	}
}

function generateAirlinesLogos(direction) {
	return [...new Set(direction.segments.map(segment => segment.airline.code))]
}
