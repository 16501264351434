<template>
    <div class="relative z-[1] bg-my-black px-4 sm:px-8 py-4 text-white">
        <FooterContent/>
    </div>
</template>

<script>
    import FooterContent from './FooterContent.vue';

    export default {
        components: {
			FooterContent
        }
    }
</script>
