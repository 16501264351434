<template>
    <div class="mt-10 sm:mt-6 lg:mt-0 text-white">
        <FooterContent/>
    </div>
</template>

<script>
    import FooterContent from './FooterContent.vue';

    export default {
        components: {
			FooterContent
        }
    }
</script>
